import { css } from '@emotion/react'
import ArrowL from '../images/arrow-l.svg'
import ArrowR from '../images/arrow-r.svg'
import ArrowD from '../images/new/icons/arrow-d.svg'

export const globalStyles = css`
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
		margin: 0 !important;
	}
	html {
		font-family: 'Barlow Semi Condensed', sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	a {
		text-decoration: none;
	}
	
	.react-responsive-modal-modal {
		background: transparent;
    box-shadow: none;
	}

	.slick-next:before {
		background: url(${ArrowR}) no-repeat;
	}
	.slick-prev:before {
		background: url(${ArrowL}) no-repeat;
	}
	.slick-prev:before, .slick-next:before {
		font-size: 80px;
		color: transparent !important;
	}
	.slick-prev {
		left: -50px;
	}

	.slick-next {
    right: -30px;
}

	.slick-dots li button:before {
		font-size: 20px;
		top: 24px;
		color: #E8E8DB;
		opacity: 1;
	}

	.slick-dots li.slick-active button:before {
    color: #375F92;
}

	.react-responsive-modal-closeButton {
		background-color: #FFFAA9;
	}

	.react-tabs {
		display: flex;
	}

	.react-tabs__tab-list {
		display: flex;
    flex-direction: column;
		width: 100%;
		border-bottom: none;
	}
	.react-tabs__tab--selected {
		background: #EF7B8E;
    border-color: transparent;
    color: black;
    border-radius: 0;
	}

	.react-tabs__tab--selected h2 {
		color: #E8E8DB !important;
	}

	.react-tabs__tab--selected p {
		color: #E8E8DB !important;
	}

	.title-wrapper {
		font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    color: #F8F8F8;
	}
	.rodal-close {
		position: absolute;
    cursor: pointer;
    top: 44px;
		right: 200px;
		width: 16px;
		height: 16px;
	}

	.rodal-close:before, .rodal-close:after {
		position: absolute;
    content: '';
    height: 2px;
    width: 340%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #FFC208;
    border-radius: 0px;
    transition: background 0.2s;
	}

	.accordion-wrapper .accordion-item h3.accordion-title button.active {
		background-color: #FFC208;
    color: #ffffff;
    border-top: none;
 	}

	.accordion-wrapper .accordion-item h3.accordion-title button.active > span{
		color: #5416FF;
	}

	 .accordion-wrapper .accordion-item h3.accordion-title button {
		position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 0px;
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
    color: #ffffff;
    font-weight: bold;
    margin-top: 0.5rem;
    padding: 1rem;
		:nth-child(3) {
      border-top: 1px solid #F8F8F8;
    	border-bottom: 1px solid #F8F8F8;
    }
	 }

	.title-wrapper {
		font-weight: 900;
		font-size: 48px;
		line-height: 58px;
		font-family: 'Lato', sans-serif;
	}

	.accordion-wrapper .accordion-item .accordion-panel .panel-open {
		overflow: hidden;
		background: transparent;
		padding: 1rem;
		margin-bottom: 1rem;
		box-sizing: border-box;
		border-radius: 1rem;
		opacity:1;
		width:100%;
		height: auto;
		transition-timing-function: ease-in;
		transition: all 1s;
		font-weight: 800;
		font-size: 18px;
		line-height: 25px;
		text-transform: uppercase;
		color: #F8F8F8;
	}

	/* .accordion-wrapper .accordion-item h3.accordion-title button:hover {
		background-color: transparent;
	} */

	.accordion__button[aria-expanded='true'], .accordion__button[aria-selected='true'] {
    background-color: #FFC208;
		max-width: 1133px;
		font-weight: 900;
		font-size: 48px;
		line-height: 58px;
		color: #5416FF;
		font-family: 'Lato', sans-serif;
	}

	.accordion__button {
    background-color: transparent;
		font-family: 'Lato', sans-serif;
    color: #F8F8F8;
		font-weight: 900;
		font-size: 48px;
		line-height: 58px;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    max-width: 1133px;
		align-items: center;
    display: flex;
		:hover {
			color: #FFC208 !important;
		}
	}

	.accordion {
    border: none;
    border-radius: 0px;
	}

	.accordion__button::before {
		display: none;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
	}

	.accordion__button::after {
		display: inline-block;
    content: '';
    height: 30px;
    width: 30px;
    margin-right: 12px;
    /* border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor; 
    transform: rotate(-45deg);*/
		margin-left: auto;
		background: url(${ArrowD}) no-repeat;
	}
	
	.accordion__item + .accordion__item {
    border-top: 1px solid #F8F8F8;
	}

	.accordion__button:hover {
		background-color: transparent;
	}

	@media (max-width: 480px) {
		.accordion {
			padding: 0px !important;
		}
    .accordion__button::after {
			padding-right: 24px !important;
		}
		.accordion__button {
			font-size: 32px;
			line-height: 0px;
			padding-left: 24px !important;
		}
		.accordion__heading {
			padding: 0px !important;
		}
		.accordion__item {
			padding: 0px !important;
		}
		.accordion__button[aria-expanded='true'], .accordion__button[aria-selected='true'] {
			font-size: 32px;
		}
  }
	/* STICK */
	#gfg-side-sticky-tab {
			position: fixed;
			top: 70vh;
			right: 50px;
			z-index: 9999;
		}
		#gfg-side-sticky-tab div {
			margin: 0px;
			padding: 0px;
		}
		#gfg-side-sticky-tab .sticky-container {
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			border-radius: 0px 0px 6px 6px;
			right: -60px;
			position: fixed;
			transition-property: right;
			transition-duration: .6s;
			width: 220px;
		}
		#gfg-side-sticky-tab .sticky-container:hover {
			right: -10px;
		}
		#gfg-side-sticky-tab .gfg-callout {
			position: relative;
			display: block;
			border-radius: 0px 0px 6px 6px;
		}
		#gfg-side-sticky-tab .slideout {
			position: relative;
			vertical-align: middle;
			height: 56px;
			display: block;
			background-color: transparent;
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			/* overflow-y: scroll; */
			direction: rtl;
			/* outline: 1px solid #ccc; */
		}
		#gfg-side-sticky-tab .slideout .inner {
			text-align: center;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item {
			padding: 0px;
			font-size: 14px;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item a {
			color: #404040;
			text-decoration: none;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item .hand-write-icon {
			font-size: 4rem;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item a:hover {
			color: #000;
		}
		#gfg-side-sticky-tab .gfg-callout .slide-out-btn {
			color: white;
			background-color: #3fa9f5;
			height: 40px;
			width: 41px;
			margin: 0;
			padding: 0;
			display: block;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			border-radius: 0px 0px 6px 6px;
			position: absolute;
			right: 5px;
			border: 0;
			cursor: pointer;
		}
`